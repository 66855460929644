import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import Section from "../components/section"
import EventCard from '../components/event-card'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const massagedData = { childMarkdownRemark: data.markdownRemark }

  return (
    <Layout>
      <Section>
        <EventCard event={massagedData}></EventCard>
      </Section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date(
          formatString: "dddd, MMMM DD YYYY"
        )
        start_time
        end_time
        location
        address
        address_link
      }
    }
  }
`